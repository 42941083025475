$animation-duration: 100ms;

:export {
  // Export the animation duration to use it as the timeout
  // in the useEffect of the Wizard component.
  animationDuration: $animation-duration;
}

.wizard {
  overflow: visible;

  width: 100%;
  height: 100%;
}

// Hide animation when going to the next step
@keyframes hide {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

// Show animation when going to the next step
@keyframes show {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

// Hide animation when going to the previous step
@keyframes hide-backwards {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

// Show animation when going to the previous step
@keyframes show-backwards {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.stepRenderer {
  width: inherit;
  height: inherit;

  pointer-events: none;

  animation: hide $animation-duration ease-in;
  animation-fill-mode: forwards;

  &.visible {
    pointer-events: inherit;
    animation: show $animation-duration ease-out;
    animation-fill-mode: forwards;
  }

  &.backwards {
    animation: hide-backwards $animation-duration ease-in;

    &.visible {
      animation: show-backwards $animation-duration ease-out;
    }
  }
}
