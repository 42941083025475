@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper :global {
  // We use :global since it allow us to target script-inserted DOM elements. Normally, when using style modules,
  // we don't have a way to add classes to elements generated by a script. Knowing beforehand the classes that the
  // script will use, this allows to target and style them.
  .gladlyHC-searchInput {
    height: 40px;
    line-height: 40px;
    border: 2px solid $color-border;
    padding: 15px;
    max-width: 550px;
    border-radius: 2px;

    &::placeholder {
      font-size: 15px;
      color: $color-black;
      font-weight: $font-weight-bold;
    }
  }

  .gladlyHC {
    // Applied wrapper style to the Gladly injected content to make sure it will remain consistent
    // with the rest of the page when soft refresh with app router
    display: flex;
    justify-content: center;
    min-height: 400px;
    max-width: 80vw;
    margin: 0 auto;
    padding-bottom: 25px;
  }

  a {
    color: $color-black;
  }
}

:is(body) .returnCenterLink {
  padding-top: 25px;
  text-decoration: underline;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 80vw;
  margin: 0 auto;
}

.topButtons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  background-color: $color-gray-light-2;
  padding: 45px;
  max-width: 80vw;
  margin: 0 auto 35px;

  @include xl-min {
    gap: 130px;
  }
}

a.button {
  text-transform: uppercase;
  color: $color-white;
  background-color: $color-primary-dark;
  height: 50px;
  line-height: 50px;
  padding: 0 40px;
  font-size: 16px;
  font-weight: 500;
  min-width: 200px;
  text-align: center;
}
